// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-blog-post-js": () => import("../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-tag-template-js": () => import("../src/templates/tag-template.js" /* webpackChunkName: "component---src-templates-tag-template-js" */),
  "component---src-pages-about-js": () => import("../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-about-section-js": () => import("../src/pages/AboutSection.js" /* webpackChunkName: "component---src-pages-about-section-js" */),
  "component---src-pages-blog-js": () => import("../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-contact-js": () => import("../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-contact-details-js": () => import("../src/pages/ContactDetails.js" /* webpackChunkName: "component---src-pages-contact-details-js" */),
  "component---src-pages-en-index-js": () => import("../src/pages/en/index.js" /* webpackChunkName: "component---src-pages-en-index-js" */),
  "component---src-pages-hr-index-js": () => import("../src/pages/hr/index.js" /* webpackChunkName: "component---src-pages-hr-index-js" */),
  "component---src-pages-index-js": () => import("../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-main-js": () => import("../src/pages/main.js" /* webpackChunkName: "component---src-pages-main-js" */),
  "component---src-pages-services-js": () => import("../src/pages/Services.js" /* webpackChunkName: "component---src-pages-services-js" */),
  "component---src-pages-tags-js": () => import("../src/pages/tags.js" /* webpackChunkName: "component---src-pages-tags-js" */),
  "component---src-pages-types-of-texts-js": () => import("../src/pages/TypesOfTexts.js" /* webpackChunkName: "component---src-pages-types-of-texts-js" */)
}

